import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-multi-carousel/lib/styles.css';
import { faFaceGrinHearts } from '@fortawesome/free-solid-svg-icons';

const Home = () => {

  return (
    <div className="home-container">
      <section id='bio'>
        <h1>Hey, this is Bheki Daweti.</h1>
        <p>
          I am an aspiring full stack developer. Currently doing a Software Development internship at OpenGenus, I also contribute to open source projects.
        </p>
        <p>
          Check out some of my work on Github:
          <FontAwesomeIcon icon={faFaceGrinHearts} />
        </p>
        <hr />
      </section>

        <div>
        <h3><a href='https://github.com/bhekidaweti'> See more work ..</a></h3>
      </div>
    </div>
  );
}

export default Home;
